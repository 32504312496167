import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { ImageWrapper } from "../elements"

export const Image = ({ fluid }) => {
  return (
    <ImageWrapper>
      <Img fluid={fluid} style={{ width: "100%" }} />
    </ImageWrapper>
  )
}
