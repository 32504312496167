import styled from "styled-components"

export const FooterWrapper = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #202325;
  padding: 1em;
  box-sizing: border-box;

  .navbar-expand .navbar-nav .nav-link {
    padding: 2em;
  }

  nav {
    max-width: 1366px;
    display: flex;
    margin: auto;
    justify-content: center;
  }

  .copyright {
    text-align: center;
  }
`

export const FooterSocialWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;

      p {
          text-align: center:
          flex: 0 0 100%;
      }
`

export const FooterSocialIcons = styled.div`
  flex: 0 0 100%;
  margin-bottom: 2rem;

  & img {
    padding: 0 1rem;
    transition: filter 0.3s ease;
  }

  & img:hover, img: focus {
    filter: brightness (50%);
  }
`
