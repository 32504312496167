import React from "react"
import { ContentContainerWrapper } from "../elements"
import { Footer, Header } from "."

export const ContentContainer = ({ children }) => {
  return (
    <ContentContainerWrapper>
      <Header />
      {children}
      <Footer />
    </ContentContainerWrapper>
  )
}
