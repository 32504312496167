// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-all-posts-garbage-js": () => import("./../../../src/templates/allPostsGarbage.js" /* webpackChunkName: "component---src-templates-all-posts-garbage-js" */),
  "component---src-templates-category-results-js": () => import("./../../../src/templates/categoryResults.js" /* webpackChunkName: "component---src-templates-category-results-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/singlePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-tag-results-js": () => import("./../../../src/templates/tagResults.js" /* webpackChunkName: "component---src-templates-tag-results-js" */)
}

