import styled from "styled-components"

// pass it to the Link element also
export const CategoryWrapper = styled.li`
  padding: 0.1em;
  text-transform: capitalize;

  span {
    padding: 0 5px;
  }

  a {
    text-decoration: none;
  }
`
