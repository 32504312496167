import styled from "styled-components"

export const HeaderWrapper = styled.header`
  position: fixed;
  z-index: 99;
  width: 100%;
  transition: 0.25s;

  &.shrink-header .navigation-wrapper {
    background: #202325;
    transition: 0.25s;
    border-bottom: 1px solid #373b3e;
  }

  &.shrink-header .navigation {
    height: 70px;
    transition: 0.25s;
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 0 1em;
    max-width: 1366px;
    height: 100px;
    transition: 0.25s;
  }

  .navigation a {
    padding: 1em;
    color: #fff;
    text-decoration: none;
  }

  .navigation a:hover {
  }

  .navigation a.active {
    text-decoration: underline;
  }

  .navigation-list {
    overflow: hidden;
    margin-bottom: 0;
  }

  .navigation-item {
    display: inline-block;
    float: left;
  }

  .navigation-toggle {
    display: none;
    color: #fff;
  }

  @media (max-width: 767px) {
    // .navigation {
    //   display: block;
    //   padding: 1em;
    // }

    .navigation-wrapper {
    }

    .navigation {
      padding: 0;
      background: #151a33;
      height: 70px;
      transition: 0.25s;
    }

    .navigation-toggle {
      display: block;
      padding: 1em;
    }

    .navigation-logo-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .navigation-list-container {
      width: 100%;
      position: absolute;
      top: 70px;
      background: #151a33;
    }

    .navigation-list-container .navigation-list.collapsed {
      height: 0;
      transition: all 0.25s;
    }

    .navigation-list-container .navigation-list.expanded {
      height: 255px;
      transition: all 0.25s;
    }

    .navigation-list {
      width: 100%;
    }

    .navigation-item {
      float: none;
      display: block;
      padding: 1em 0;
      margin-bottom: 1em;
    }
  }
`

export const NavWrapper = styled.nav`
  grid-column: 2 / span 12;
  grid-row: 1 / 2;
  display: flex;
  align-items: center;
  @media ${props => props.theme.breakpoints.tablet} {
    grid-column: 2 / span 6;
  }
`

// .navbar {
//   color: #ccc;
//   height: ${({ shrink }) => (shrink ? "60px" : "100px")};
//   transition: 0.25s;
// }

// .navbar .nav-link {
//   color: #fff;
// }

// .navbar .nav-link.active {
//   border-bottom: 1px solid grey;
// }

// .navbar .icons {
//   flex-direction: row;
// }

// #header-top .nav-link {
//   margin: 1.5em;
// }

// #header-bottom {
//   color: #fff;
// }

// #header-bottom .nav-link {
//   padding: 1em;
// }

// @media (max-width: 768px) {
//   .navbar-collapse {
//     background: #202325;
//   }
// }
