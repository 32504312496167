import styled from "styled-components"

export const PostWrapper = styled.main`
  display: flex;
  flex-wrap: wrap;
  padding-top: 100px;
  padding-bottom: 150px;
  min-height: calc(100vh - 100px);
  background: linear-gradient(#131931, #423e61);
  background-image: url("/images/Sky.png");
  background-repeat: no-repeat;
  background-position: center top;

  .container {
    flex-direction: column;
  }

  .post {
    margin: auto;
    max-width: 800px;
    top: -150px;
    position: relative;
    background-color: #131931;
    padding: 2em;
  }

  .post-content {
    margin-top: 2em;
  }

  h1 {
    font-size: 36px;
    margin-bottom: 0;
    text-align: left;
  }

  h2 {
    font-weight: 600;
    margin-top: 2em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #aaa;
  }

  h3 {
    font-weight: 600;
    margin-top: 1em;
  }

  p {
    margin-top: 1em;
    line-height: 1.5em;
    font-size: 1em;
  }

  li {
    margin-top: 1em;
    margin-left: 1em;
    line-height: 1.5em;
  }

  p.post-date {
    margin-top: 0.25em;
  }
`
export const PostCardWrapper = styled.div`
  display: flex;
  width: calc(50% - 2em);
  margin: 1em;

  .card {
    display: flex;
    flex-direction: column;
    height: 335px;
    width: 100%;
    padding: 1.5em;
    background: #232642;
    border-top: 3px solid #9980a8;
  }

  .card-title {
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize
  }

  .card-body {
    display: flex;
    flex-direction: column;
    color: #ccc;
    flex-grow: 1;
  }

  .card-img-top {
    padding: 0;
    max-height: 150px;
  }

  .card-date {
    margin-bottom: .5em;
    font-size: 16px;
    color: #888;
  }

  .card-text {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 1em;
    line-height: 1.3em;
  }

  @media (max-width: 980px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin 0;
    margin-bottom: 1em;
  }
`
// can add generic styling for markdown posts here
