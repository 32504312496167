import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { PostCardWrapper } from "../elements"
import { Image } from "../components"

export const PostCard = props => {
  const data = useStaticQuery(graphql`
    query {
      imageSharp(fluid: { originalName: { eq: "coding_default.jpg" } }) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return (
    <PostCardWrapper>
      <div className="card">
        <Image
          fluid={props.fluidImage ? props.fluidImage : data.imageSharp.fluid}
        />
        <div className="card-body">
          <h1 className="card-title">{props.title}</h1>
          <h2 className="card-date">{props.date}</h2>
          <p className="card-text">{props.description}</p>
        </div>
        <Link to={"/posts/" + props.to}>
          <button className="link-button read-more-button">READ MORE</button>
        </Link>
      </div>
    </PostCardWrapper>
  )
}
