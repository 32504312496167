const theme = {
  fonts: {
    main: "Muli, sans-serif",
    code: "Roboto Mono, monospace",
  },
  colors: {
    main1: "red",
  },
  shadows: {
    shadow1: "none",
  },
  breakpoints: {
    tablet: "767px",
  },
}

export default theme
