import React from "react"
import { MainContentWrapper, BlogContentWrapper, HomeContentWrapper } from "../elements"

export const MainContent = ({ children }) => {
  return <MainContentWrapper>{children}</MainContentWrapper>
}

export const BlogContent = ({ children }) => {
  return <BlogContentWrapper>{children}</BlogContentWrapper>
}

export const HomeContent = ({ children }) => {
  return <HomeContentWrapper>{children}</HomeContentWrapper>
}
