import styled from "styled-components"

export const ContentContainerWrapper = styled.div`
  position: relative;
  height: 100%;
  margin: auto;
`

// export const ContainerWrapper = styled.div`
// 	height: 100%;
// 	display: grid;
// 	grid-template-columns: 1fr repeat(12, minmax(auto; 4.2rem)) 1fr;
// 	grid-template-row: 7.8rem 20rem 5rem auto;
// 	grid-gap: 0 2rem;

// 	@media $(props => props.theme.breakpoints.tablet} {
// 		grid-template-columns: 2rem repeat(6, 1fr) 2rem;
// 		grid-gap: 0 1rem;
// 	}
// 	@media $(props => props.theme.breakpoints.mobile} {
// 		grid-template-columns: 1rem repeat(6, 1fr) 1rem;
// 	}
// `

export const HomeContentWrapper = styled.main`
  padding-top: 0;
  padding-bottom: 100px;
  background: #161b34;
  overflow-x: hidden;
`

export const BlogContentWrapper = styled.main`
  display: flex;
  flex-wrap: wrap;
  padding-top: 100px;
  padding-bottom: 150px;
  min-height: calc(100vh - 100px);
  background: linear-gradient(#131931, #423e61);
  background-image: url("/images/Sky.png");
  background-repeat: no-repeat;
  background-position: center top;

  .post {
    margin: auto;
    max-width: 800px;
    top: -150px;
    position: relative;
    background-color: #131931;
    padding: 2em;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    margin: 0;
  }

  @media (max-width: 767px) {
    padding-top: 70px;
  }
`

export const MainContentWrapper = styled.main`
  display: flex;
  flex-wrap: wrap;
  padding-top: 70px;
  padding-bottom: 150px;
  min-height: calc(100vh - 100px);
  background: linear-gradient(#131931, #423e61);
`
