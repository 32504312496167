import React from "react"
import { PostWrapper } from "../elements"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Image } from "../components"

export const Post = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     imageSharp(fluid: { originalName: { eq: "coding_default.jpg" } }) {
  //       fluid {
  //         ...GatsbyImageSharpFluid
  //       }
  //     }
  //   }
  // `)

  return (
    <PostWrapper>
      <div className="container">
        {/* <div class="post-image">
          <Image fluid={data.imageSharp.fluid} />
        </div> */}
        <div className="post">{children}</div>
      </div>
    </PostWrapper>
  )
}
