import React from "react"
import { CategoryWrapper } from "../elements"
import { Link } from "gatsby"

export const Category = ({ children, href, count }) => {
  return (
    <CategoryWrapper>
      <Link to={href}>{children}</Link>
      <span>({count})</span>
    </CategoryWrapper>
  )
}
