import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// pass it to the Link element also
export const TagWrapper = styled(props => <Link {...props} />)`
  padding: 0.25em;
  margin: 3px;
  background: #27609e;
  color: white;
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;

  :hover {
    background-color: #0069d9;
    color: #fff;
    text-decoration: none;
  }
`
