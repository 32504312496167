import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FooterWrapper } from "../elements"
import { SocialButton } from "../components"
import { FaGithub, FaTwitter, FaLinkedin } from "react-icons/fa"

export const Footer = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     github: file(relativePath: { eq: "github.png" }) {
  //       publicURL
  //     }
  //     twitter: file(relativePath: { eq: "twitter.png" }) {
  //       publicURL
  //     }
  //     linkedin: file(relativePath: { eq: "linkedin.png" }) {
  //       publicURL
  //     }
  //   }
  // `)

  return (
    <FooterWrapper>
      {/* <Navbar expand="lg" id="header-top">
        <Container>
          <Nav className="me-auto justify-content-end">
            <Nav.Link href="github.com">
              <FaGithub />
            </Nav.Link>
            <Nav.Link href="github.com">
              <FaTwitter />
            </Nav.Link>
            <Nav.Link href="github.com">
              <FaLinkedin />
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar> */}

      <div className="">
        <nav className="icons">
          <a
            href="https://github.com/leliu1224"
            className="social-button"
            target="_blank"
          >
            <span>
              <FaGithub />
            </span>
          </a>
          <a
            href="https://twitter.com/leliu0864"
            className="social-button"
            target="_blank"
          >
            <span>
              <FaTwitter />
            </span>
          </a>
          <a
            href="https://www.linkedin.com/in/leliu864"
            className="social-button"
            target="_blank"
          >
            <span>
              <FaLinkedin />
            </span>
          </a>
        </nav>
        <p className="copyright">Copyright &copy; 2021 Le Liu</p>
      </div>
    </FooterWrapper>
  )
}
