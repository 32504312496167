import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { HeaderWrapper } from "../elements"

// import { FaGithub, FaTwitter, FaLinkedin } from "react-icons/fa"
import { BsList, BsX } from "react-icons/bs"

// // HeaderLink component
// const HeaderLink = props => <Link to={props.to}>{props.text}</Link>

// // HomeButton component
// const HomeButton = props => <Link to={props.to}>{props.text}</Link>

// // SocialButton component
// const SocialButton = props => {
//   let style = ""
//   let url = ""

//   if (props.site === "twitter") {
//     url = "https://twitter.com/" + props.username
//   } else if (props.site === "linkedin") {
//     url = "https://www.linkedin.com/in/" + props.username
//   } else if (props.site === "github") {
//     url = "https://www.github.com/" + props.username
//   }

//   return (
//     <a href={url} target="_blank" rel="noopener noreferrer">
//       <button>{props.children}&nbsp;</button>
//     </a>
//   )
// }

export const Header = () => {
  const [expandMobile, SetExpandMobile] = useState(false)
  const [shrink, setShrink] = useState(false)

  const shrinkNav = () => {
    if (window.scrollY >= 50) {
      setShrink(true)
    } else {
      setShrink(false)
    }
  }

  useEffect(() => {
    shrinkNav()
    window.addEventListener("scroll", shrinkNav)
    return () => window.removeEventListener("scroll", shrinkNav)
  })

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <HeaderWrapper className={`${shrink ? "shrink-header" : ""}`}>
      <div className="navigation-wrapper">
        <nav className="navigation">
          <div className="navigation-logo-container">
            <a href="/">{data.site.siteMetadata.title}</a>
            <button
              className="navigation-toggle link-button"
              onClick={() => SetExpandMobile(!expandMobile)}
            >
              {expandMobile ? <BsX /> : <BsList />}
            </button>
          </div>
          <div className="navigation-list-container">
            <ul
              className={`navigation-list ${
                expandMobile ? "expanded" : "collapsed"
              }`}
            >
              <li className="navigation-item">
                <Link
                  to="/"
                  activeClassName="active"
                  className="navigation-link"
                >
                  HOME
                </Link>
              </li>
              <li className="navigation-item">
                <Link
                  to="/blog"
                  activeClassName="active"
                  className="navigation-link"
                >
                  TECH BLOG
                </Link>
              </li>

              <li className="navigation-item">
                <Link
                  to="/guides"
                  activeClassName="active"
                  className="navigation-link"
                >
                  GAMING GUIDES
                </Link>
              </li>
              {/* <li className="navigation-item">
                <Link
                  to="/gallery"
                  activeClassName="active"
                  className="navigation-link"
                >
                  GALLERY
                </Link>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    </HeaderWrapper>
  )

  // return (
  //   <HeaderWrapper className="testing" shrink={shrink}>
  //     <Container>
  //       <Navbar variant="dark" expand="lg" id="header-top">
  //         <Navbar.Brand href="/">{data.site.siteMetadata.title}</Navbar.Brand>
  //         <Navbar.Toggle aria-controls="basic-navbar-nav" />
  //         <Navbar.Collapse id="basic-navbar-nav">
  //           <Nav className="me-auto justify-content-end ml-auto">
  //             <Link to="/" activeClassName="active" className="nav-link">
  //               HOME
  //             </Link>
  //             <Link to="/blog" activeClassName="active" className="nav-link">
  //               BLOG
  //             </Link>
  //             <Link to="/guides" activeClassName="active" className="nav-link">
  //               GAMING
  //             </Link>
  //             <Link to="/gallery" activeClassName="active" className="nav-link">
  //               GALLERY
  //             </Link>
  //           </Nav>
  //         </Navbar.Collapse>
  //       </Navbar>
  //     </Container>
  //   </HeaderWrapper>
  // )
}
