import styled from "styled-components"

export const ImageWrapper = styled.div`
    display: flex;
    overflow: hidden;
    position relative;
    width: 100%;
    text-align: center;
`

// export const FeatureImageWrapper = styled.div`
//     grid-column: 3 / span 10;
//     grid-row: 2 / 4;
//     overflow: hidden;
//     position relative;
// `
