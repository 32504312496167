import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// pass it to the Link element also
export const ButtonWrapper = styled(props => <Link {...props} />)`
  padding: 1rem;
`

export const SocialButtonWrapper = styled(props => <Link {...props} />)`
    font-size: 16px;
    line-height: 16px;
    width: 16px;
    height: 16px;
    margin: .5em;
    padding: .5em;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    color: white;
    border-radius: 100%;
    outline: none;
    text-align: center;
    background-color: #27609e;

    :hover {
      color: #fff;
      background-color: #0069d9;
    }
}
`
